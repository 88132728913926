var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table-page", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.isLoaded,
        expression: "isLoaded",
      },
    ],
    attrs: {
      tableName: _vm.$options.name,
      columns: _vm.columns,
      filterModel: _vm.filterModel,
      headerTitle: "typesofaccommod",
      tooltipMsg: "pmanualofautype",
      requestFunction: _vm.requestFunction,
      defaultOrderBy: { column: "AutypeId", ascending: 0 },
      modelId: "AutypeId",
    },
    on: {
      addNewRecord: function ($event) {
        return _vm.redirectToDetail()
      },
      editRecord: function ($event) {
        return _vm.redirectToDetail($event)
      },
      filterChanged: function ($event) {
        return _vm.handleFilter($event)
      },
      removeFilters: function ($event) {
        return _vm.removeAllFilters($event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "AutypeHasExtendedForm",
        fn: function (list) {
          return [
            _vm._v(
              "\n\t\t" +
                _vm._s(
                  list.row.AutypeHasExtendedForm
                    ? _vm.$t("long")
                    : _vm.$t("short")
                ) +
                "\n\t"
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }