<template>
	<table-page
		v-show="isLoaded"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="typesofaccommod"
		tooltipMsg="pmanualofautype"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'AutypeId', ascending: 0 }"
		modelId="AutypeId"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
	>
		<template #AutypeHasExtendedForm="list">
			{{ list.row.AutypeHasExtendedForm ? $t('long') : $t('short') }}
		</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import * as links from '@/router/links';
import BooleanDisplay from '@/components/common/boolean-display';

import { tableColumns } from './accomodation-unit-type-table.data';

export default {
	name: 'AccomodationUnitTypeTable',

	components: {
		TablePage,
		BooleanDisplay,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'AccomodationUnitType',
			columns: tableColumns,
		};
	},

	methods: {
		redirectToDetail(id = 0) {
			this.$router.push({
				name: links.accomodationUnitTypeDetailName,
				params: { id },
			});
		},
	},
};
</script>