import i18n from '@/services/lang';

export const tableColumns = [
	{
		model: 'AutypeId',
		i18n: 'id2541',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'AutypeNameSk',
		i18n: 'namesk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AutypeNameLokalSk',
		i18n: 'localsk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AutypeNameCz',
		i18n: 'namecz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AutypeNameLokalCz',
		i18n: 'localcz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AutypeNamePl',
		i18n: 'namepl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AutypeNameLokalPl',
		i18n: 'localpl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AutypeNameEn',
		i18n: 'nameen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AutypeNameLokalEn',
		i18n: 'locallen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AutypeNameDe',
		i18n: 'namede',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AutypeNameLokalDe',
		i18n: 'localde',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AutypeNameHu',
		i18n: 'namehu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AutypeNameLokalHu',
		i18n: 'localhu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'AutypeHasExtendedForm',
		i18n: 'form',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [
				{ value: null, text: '' },
				{ value: 1, text: i18n.t('long') },
				{ value: 0, text: i18n.t('short') },
			],
		},
	},
	{
		model: 'BedroomCountMin',
		i18n: 'minbedrooms',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'BedroomCountMax',
		i18n: 'maxbedrooms',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
];
